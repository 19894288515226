import React from "react"

const MetroFares = () => (
  <>
    <h2 style={{ margin: '0 0 20px 0'}}>24/25 fares</h2>
    <p>Payment options on this service:</p>
    <ul>
      <li><strong>M-Card App</strong>. <a href="https://www.m-card.co.uk/" target="_blank">Download the M-Card app</a> and purchase tickets online.</li>
      <li><strong>Under 19 photocard</strong>. Pre-load tickets to an <a href="https://ticketsandpasses.wymetro.com/under-26-students/under-19s/" target="_blank">under 19 photocard</a>.</li>
      <li><strong>Cash</strong>. The fare is £1.20 each way. Try to bring the exact change. Please note this is the most expensive way to travel. Discounts are available with the M-Card app and the under 19 photocard listed above.</li>
    </ul>
  </>
)

export default MetroFares